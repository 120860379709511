/* FWK Variables to CSS Classes */

// color
.--c-range07_60 {
  color: var(--range07_60);
}

.--c-range08_60 {
  color: var(--range08_60);
}

.--c-range11_60 {
  color: var(--range11_60);
}
