@import '@mercadona/styles/sass/mixins';

// HEADING REGULAR
.--font-heading04-regular {
  @include font-heading04-regular;
}

.--font-heading06-regular {
  @include font-heading06-regular;
}

// HEADING SEMIBOLD
.--font-heading05-semibold {
  @include font-heading05-semibold;
}
