/* Component MTable */
table.m-table {
  &.m-table-border {
    border-right: 1px solid var(--activeUi);
    border-left: 1px solid var(--activeUi);

    tr:not(:last-of-type) {
      .m-cell {
        border-bottom: 1px solid var(--activeUi);

        &:not(:last-of-type) {
          border-right: 1px solid var(--activeUi);
        }
      }
    }
  }

  &.m-table-flat .m-cell {
    background-color: var(--field01);
  }

  &.--compact {
    .m-header-cell,
    .m-cell {
      padding: 0.25rem 0.5rem;
    }
  }
}
