/* You can add global styles to this file, and also import other style files */

//noinspection CssNoGenericFontName
html,
body {
  height: 100%;
}

.main {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.hidden-element {
  display: none;
}

.hand-cursor {
  cursor: pointer;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.m-progress-bar-line.m-progress-bar-container {
  border: none !important;
}

.heigth-progress-bar {
  .m-progress-bar-container {
    height: 80px !important;
    background-color: var(--range12_20) !important;
    border: none;
  }
}

@media (max-width: 1600px) {
  .heigth-progress-bar {
    .m-progress-bar-container {
      height: 70px !important;
    }
  }
}

@media (max-width: 1450px) {
  .heigth-progress-bar {
    .m-progress-bar-container {
      height: 60px !important;
    }
  }
}

@media (max-width: 1250px) {
  .heigth-progress-bar {
    .m-progress-bar-container {
      height: 50px !important;
    }
  }

  .main-color-text {
    color: var(--interactive01);
  }
}

/* FWK Overrides */
@import 'styles/_fwk-variables-colors.scss';
@import 'styles/_fwk-variables-typography.scss';
@import 'styles/components/m-snackbar';
@import 'styles/components/m-button';
@import 'styles/components/m-form-field';
@import 'styles/components/m-list';
@import 'styles/components/m-paginator';
@import 'styles/components/m-table';
