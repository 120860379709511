/* Component MList */
m-list {
  &.--no-header-actions {
    .m-list-header {
      padding-right: 1rem !important;

      .m-list-actions {
        display: none !important;
      }
    }
  }
}

m-list-advanced-item {
  &.--fluid {
    .m-list-advanced-item-container {
      align-items: stretch !important;
    }
  }
}
