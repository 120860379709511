/* Component MFormField */
m-form-field {
  position: relative;

  &.--no-label {
    &.m-form-field-normal,
    &.m-form-field-small {
      .m-form-field-label {
        display: none !important;
      }
    }

    &.m-form-field-normal .m-form-field-flex {
      margin-top: -1px !important;
    }

    &.m-form-field-small .m-form-field-flex {
      margin-top: 0 !important;
    }

    // TODO: ? position element in another place
    .__field-custom-action {
      display: none;
    }
  }

  &.--no-subscript-wrapper {
    margin-bottom: -22px !important;
  }

  .__field-custom-action {
    z-index: 1;
    font-weight: 600;
    font-size: 0.7vw;
    color: var(--destructive01);
    cursor: pointer;
  }

  .m-form-field-suffix,
  .m-form-field-prefix {
    display: none !important;
  }
}
